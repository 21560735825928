import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { LeadValues } from '../../interfaces/leads';
import { Topic } from '../../interfaces/topics';
import {
    Website,
    WebsiteChangeContentIndicatorRequest,
    WebsiteChangeTopicsRequest,
    WebsiteEditNoteRequest,
    WebsiteGetAllResponse,
    WebsiteGetLeadsResponse,
    WebsiteLanguage,
    WebsitesByPageRequest,
    WebsitesSearchFiltersSortsQueries
} from '../../interfaces/websites';
import { Routes } from '../../typedef';
import { generateQueryForFilterWebsites } from '../../utils/query';

export const websitesApi = createApi({
    reducerPath: 'websitesApi',
    refetchOnMountOrArgChange: true,
    tagTypes: [
        'AllWebsites',
        'WebsitesLeads'
    ],
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        credentials: 'include'
    }),
    endpoints: (builder) => ({
        getWebsiteById: builder.query<Website, string>({
            query: (id: string) => `${Routes.WEBSITES}/view/${id}`
        }),

        getAllWebsites: builder.query<WebsiteGetAllResponse, Omit<WebsitesByPageRequest, 'id'>>({
            providesTags: ['AllWebsites'],
            query: (params) => {
                const {
                    filterByAccessability,
                    filterByAhrefsDr,
                    filterByAhrefsTraffic,
                    filterByCreatingDate,
                    filterByLanguage,
                    filterByLastCheckingDate,
                    filterByList,
                    filterByLrtPowerTrustDom,
                    filterByManualVerification,
                    filterByManualVerificationDate,
                    page,
                    search,
                    sortBy,
                } = params;

                const queryParams = new URLSearchParams();
                queryParams.append('page', String(page));

                if (search) {
                    queryParams.append(WebsitesSearchFiltersSortsQueries.SEARCH, search.replace('://', '[||]'));
                }

                if (sortBy && sortBy.length && sortBy.toString() !== 'number,asc') {
                    queryParams.append(WebsitesSearchFiltersSortsQueries.SORT_BY, sortBy.toString());
                }

                const filterParams = generateQueryForFilterWebsites({
                    filterByAccessability,
                    filterByLanguage,
                    filterByLrtPowerTrustDom,
                    filterByAhrefsDr,
                    filterByAhrefsTraffic,
                    filterByManualVerification,
                    filterByManualVerificationDate,
                    filterByLastCheckingDate,
                    filterByCreatingDate,
                    filterByList,
                });

                if (filterParams) {
                    queryParams.append('filters', filterParams);
                }

                return `${Routes.WEBSITES}?${queryParams.toString()}`;
            },
        }),

        getWebsitesLeads: builder.query<WebsiteGetLeadsResponse, string>({
            query: (id: string) => `${Routes.WEBSITES}/${id}/leads`,
            providesTags: ['WebsitesLeads']
        }),

        getWebsitesLanguage: builder.query<WebsiteLanguage[], void>({
            query: () => `${Routes.WEBSITES}/languages`
        }),

        changeTopics: builder.mutation<Topic[], WebsiteChangeTopicsRequest>({
            query: (values) => ({
                url: `${Routes.WEBSITES}/change-topics`,
                method: 'POST',
                body: values
            }),
            invalidatesTags: ['AllWebsites']
        }),

        editNote: builder.mutation<void, WebsiteEditNoteRequest>({
            query: (values) => ({
                url: `${Routes.WEBSITES}/edit-note`,
                method: 'POST',
                body: values
            }),
            invalidatesTags: ['AllWebsites']
        }),

        createNewLead: builder.mutation<void, LeadValues>({
            query: (values) => ({
                url: `${Routes.LEADS}/create`,
                method: 'POST',
                body: values
            }),
            invalidatesTags: [
                'AllWebsites',
                'WebsitesLeads'
            ]
        }),

        changeContentIndicators: builder.mutation<void, WebsiteChangeContentIndicatorRequest>({
            query: (values) => ({
                url: `${Routes.WEBSITES}/change-content-indicator`,
                method: 'POST',
                body: values
            }),
            invalidatesTags: ['AllWebsites']
        })
    })
});

export const {
    useGetAllWebsitesQuery,
    useGetWebsitesLeadsQuery,
    useGetWebsitesLanguageQuery,
    useChangeTopicsMutation,
    useEditNoteMutation,
    useCreateNewLeadMutation,
    useChangeContentIndicatorsMutation,
    useGetWebsiteByIdQuery
} = websitesApi;
