import { Button, Form, message, Space } from 'antd';
import React, { FC, memo, useCallback, useEffect } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { LeadForm } from '../../../components/LeadForm';
import { Modal } from '../../../components/Modal';
import { LeadFields, LeadInputs, LeadValues } from '../../../interfaces/leads';
import { RTKError } from '../../../store';
import { useGetLeadsLanguageQuery } from '../../../store/queries/leads';
import { useCreateNewLeadMutation } from '../../../store/queries/websites';

interface Props {
    isVisible: boolean;
    handleClose: () => void;
}

export const CreateLeadModal: FC<Props> = memo(({ isVisible, handleClose }) => {
    const [form] = Form.useForm<LeadInputs>();

    const [createNewLeadPost, createNewLeadPostResult] = useCreateNewLeadMutation();

    const leadsLanguagesList = useGetLeadsLanguageQuery();

    const initialValues: LeadValues = {
        [LeadFields.URL]: undefined,
        [LeadFields.DOMAIN]: undefined,
        [LeadFields.SUBDOMAIN]: undefined,
        [LeadFields.TYPE]: undefined,
        [LeadFields.ENGINE]: undefined,
        [LeadFields.HOME_PAGE]: undefined,
        [LeadFields.LANGUAGE]: undefined,
        [LeadFields.STATUS]: undefined,
        [LeadFields.STATUS_CODE]: undefined,
        [LeadFields.CONNECTION_STATUS]: undefined,
        [LeadFields.META_TITLE]: undefined,
        [LeadFields.BLOG_PROBABILITY]: false,
        [LeadFields.MANUAL_VERIFICATION]: undefined,
        [LeadFields.LINK_INFO_LRT_POWER_TRUST_DOM]: undefined,
        [LeadFields.LINK_INFO_LRT_POWER_TRUST]: undefined,
        [LeadFields.LINK_INFO_LRT_POWER_DOM]: undefined,
        [LeadFields.LINK_INFO_LRT_TRUST_DOM]: undefined,
        [LeadFields.LINK_INFO_LRT_TITLE_HOME]: undefined,
        [LeadFields.LINK_INFO_LRT_THEME]: undefined,
        [LeadFields.LINK_INFO_LRT_IP]: undefined,
        [LeadFields.LINK_INFO_AHREFS_DOMAIN_RATING]: undefined,
        [LeadFields.LINK_INFO_AHREFS_TOTAL_TRAFFIC]: undefined,
        [LeadFields.CONTENT_INDICATORS]: [],
        [LeadFields.REGULAR_URL]: undefined,
        [LeadFields.REGULAR_LOGIN]: undefined,
        [LeadFields.REGULAR_PASSWORD]: undefined,
        [LeadFields.SHELLY_URL]: undefined,
        [LeadFields.SHELLY_PASSWORD]: undefined,
        [LeadFields.SHELLY_IS_FILE_UPLOAD]: false,
        [LeadFields.SHELLY_PATH_TO_FILE]: undefined
    };

    useEffect(() => {
        if (!isVisible) {
            form.resetFields();
        }
    }, [isVisible]);

    useEffect(() => {
        if (leadsLanguagesList.isError) {
            void message.error({ content: (leadsLanguagesList.error as RTKError).data.message });
        }
    }, [leadsLanguagesList.isError]);

    useEffect(() => {
        if (createNewLeadPostResult.isError) {
            void message.error({
                content: (createNewLeadPostResult.error as RTKError).data.message,
                key: 'createTopicPost'
            });
        }

        if (createNewLeadPostResult.isSuccess) {
            void message.success({ content: 'New lead was created successfully!', key: 'createTopicPost' });
            handleClose();
        }

        if (createNewLeadPostResult.isLoading) {
            void message.loading({ content: 'Send request..', key: 'createTopicPost' });
        }
    }, [createNewLeadPostResult]);

    const onSubmit: SubmitHandler<LeadInputs> = useCallback((data: LeadValues) => {
        if (!data.link_info_lrt_ip) {
            data.link_info_lrt_ip = undefined;
        }

        if (data.status_code) data.status_code = +data.status_code;
        if (data.link_info_lrt_power_trust_dom) data.link_info_lrt_power_trust_dom = +data.link_info_lrt_power_trust_dom;
        if (data.link_info_lrt_power_trust) data.link_info_lrt_power_trust = +data.link_info_lrt_power_trust;
        if (data.link_info_lrt_power_dom) data.link_info_lrt_power_dom = +data.link_info_lrt_power_dom;
        if (data.link_info_lrt_trust_dom) data.link_info_lrt_trust_dom = +data.link_info_lrt_trust_dom;
        if (data.link_info_ahrefs_domain_rating) data.link_info_ahrefs_domain_rating = +data.link_info_ahrefs_domain_rating;
        if (data.link_info_ahrefs_total_traffic) data.link_info_ahrefs_total_traffic = +data.link_info_ahrefs_total_traffic;
        if (data.shelly_is_file_upload) data.shelly_is_file_upload = `${data.shelly_is_file_upload}` === 'true';

        for (let i = 0; i < Object.values(data).length; i++) {
            if (Object.values(data)[i] === '' || JSON.stringify(Object.values(data)[i]) === JSON.stringify({
                name: undefined,
                iso_name: undefined
            })) {
                data[Object.entries(data)[i][0] as keyof LeadValues] = undefined;
            }
        }

        createNewLeadPost(data);
    }, []);

    const handleCreate = useCallback((): void => {
        form.submit();
    }, []);

    return (
        <Modal
            width={1200}
            title="Add new lead"
            open={isVisible}
            onCancel={handleClose}
            confirmLoading={createNewLeadPostResult.isLoading}
            footer={[
                <Space style={{ marginLeft: 'auto', width: 'fit-content' }} key="footer">
                    <Button key="cancle" onClick={handleClose} danger>Cancel</Button>
                    <Button
                        loading={createNewLeadPostResult.isLoading} onClick={handleCreate} type="primary"
                    >Create</Button>
                </Space>
            ]}
        >
            <LeadForm
                formName="lead-create"
                form={form}
                defaultValues={initialValues}
                disabled={createNewLeadPostResult.isLoading}
                leadsLanguagesList={leadsLanguagesList.data ?? []}
                onSubmit={onSubmit}
            />
        </Modal>
    );
});
