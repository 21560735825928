import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { LeadManualVerification } from '../interfaces/leads';
import { LeadsListResponseWithMeta } from '../interfaces/leadsLists';
import { fetchLeadsByPageCount } from '../store/actions/leadsLists';
import { manualVerifyLead } from '../store/slices/leadsList';
import { RoutesParams, SocketsKeys } from '../typedef';

interface Props {
    currentPage: number;
    setCurrentPage: (val: number) => void;
    handleClose: () => void;
}

export const useInitPageSockets = ({ currentPage, setCurrentPage, handleClose }: Props): void => {
    const dispatch = useDispatch();
    const { list_id: listIdParam } = useParams<RoutesParams.LIST_ID>();
    const {
        isLoading: listIsLoading,
        currentListInfo
    } = useSelector((state) => state.leadsLists);

    useEffect(() => {
        if (!listIsLoading && currentListInfo) {
            const realTimeLeadsListRestart = (data: LeadsListResponseWithMeta): void => {
                if (listIdParam === data.data._id) {
                    listIdParam && dispatch(fetchLeadsByPageCount({ id: listIdParam, page: currentPage }));
                }
            };
            window.clientIo?.on(SocketsKeys.RESTART_LIST, realTimeLeadsListRestart);

            const realTimeDeleteLeadsList = (listID: string): void => {
                if (listID === listIdParam) handleClose();
            };
            window.clientIo?.on(SocketsKeys.REMOVE_LEADS_LIST, realTimeDeleteLeadsList);

            const realTimeUpdateLeadManualVerification = (
                leadID: string,
                verification: LeadManualVerification,
                verificatedAt: Date
            ): void => {
                dispatch(manualVerifyLead({ leadID, verification, verificatedAt }));
            };
            window.clientIo?.on(SocketsKeys.UPDATE_LEAD_MANUAL_VERIFICATION, realTimeUpdateLeadManualVerification);

            return () => {
                window.clientIo?.off(SocketsKeys.RESTART_LIST, realTimeLeadsListRestart);
                window.clientIo?.off(SocketsKeys.REMOVE_LEADS_LIST, realTimeDeleteLeadsList);
                window.clientIo?.off(SocketsKeys.UPDATE_LEAD_MANUAL_VERIFICATION, realTimeDeleteLeadsList);
            };
        }
    }, [listIsLoading]);
};
